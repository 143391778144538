<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-between flex-column"
              >
                <div class="d-flex flex-column justify-content-start">
                  <b-avatar
                    :src="`https://api.the-netwerk.com/public/storage/member/profile/${data.photo_profile}`"
                    :text="data.name | initial"
                    size="104px"
                    rounded
                  />

                  <div class="name-status mb-2 mt-1">
                    <h1 class="font-weight-bolder">
                      {{ data.name }}
                    </h1>
                    <b-badge
                      v-if="data.status == 'active'"
                      variant="light-success"
                    >
                      Active
                    </b-badge>
                    <b-badge
                      v-else-if="data.status == 'inactive'"
                      variant="light-secondary"
                    >
                      Inactive
                    </b-badge>
                    <b-badge
                      v-else-if="data.status == 'suspended'"
                      variant="light-warning"
                    >
                      Suspended
                    </b-badge>
                    <b-badge
                      v-else-if="data.status == 'banned'"
                      variant="light-danger"
                    >
                      Banned
                    </b-badge>
                  </div>

                  <div class="d-flex flex-column mb-2">
                    <h4 class="font-weight-bolder mb-1">
                      Member details
                    </h4>
                    <dl class="row my-25">
                      <dt class="col-12 col-sm-4 text-muted">
                        Username
                      </dt>
                      <dd class="col-12 col-sm-8">
                        {{ data.username }}
                      </dd>
                    </dl>
                    <dl class="row my-25">
                      <dt class="col-12 col-sm-4 text-muted">
                        Email
                      </dt>
                      <dd class="col-12 col-sm-8">
                        {{ data.email | handleNullValue }}
                      </dd>
                    </dl>
                    <dl class="row my-25">
                      <dt class="col-12 col-sm-4 text-muted">
                        Contact
                      </dt>
                      <dd class="col-12 col-sm-8">
                        {{ data.phone_number | phone(data.phone_code, data.phone_number) }}
                      </dd>
                    </dl>
                    <dl class="row my-25">
                      <dt class="col-12 col-sm-4 text-muted">
                        Gender
                      </dt>
                      <dd class="col-12 col-sm-8">
                        {{ data.gender | handleNullValue }}
                      </dd>
                    </dl>
                    <dl class="row my-25">
                      <dt class="col-12 col-sm-4 text-muted">
                        Profile
                      </dt>
                      <dd class="col-12 col-sm-8">
                        {{ data.profile | handleNullValue }}
                      </dd>
                    </dl>
                  </div>

                  <div class="d-flex flex-column mb-2">
                    <h4 class="font-weight-bolder mb-1">
                      Roles
                    </h4>
                    <b-row>
                      <b-col cols="12">
                        <b-badge variant="light-info">
                          Corporate
                        </b-badge>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-card no-body>
          <b-card-body>
            <b-tabs>
              <b-tab
                v-if="data.corporate_id"
                title="Corporate"
              />
              <b-tab
                v-else
                title="Company"
              >
                <member-company />
              </b-tab>
              <b-tab title="Social Media">
                <member-socmed />
              </b-tab>
              <b-tab title="Address">
                <member-address />
              </b-tab>
              <b-tab title="Billing">
                <member-billing />
              </b-tab>
              <b-tab title="Logs" />
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="edit-role"
      cancel-variant="outline-secondary"
      ok-title="Edit"
      cancel-title="Close"
      centered
      title="Edit Role"
      @ok="editRoleUser"
    >
      <b-form>
        <b-form-group>
          <label for="role">Role</label>
          <b-form-select
            id="role"
            v-model="data.role"
            :options="options_role"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BTab, BTabs,
  BCard, BCardBody,
  BAvatar, BBadge, BModal,
  BForm, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import MemberCompany from './detail/MemberCompany.vue'
import MemberSocmed from './detail/MemberSocmed.vue'
import MemberAddress from './detail/MemberAddress.vue'
import MemberBilling from './detail/MemberBilling.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BCardBody,
    BAvatar,
    BBadge,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
    MemberCompany,
    MemberSocmed,
    MemberAddress,
    MemberBilling,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show_modal_edit_role: false,
      options_role: [
        { value: 'Anggota', text: 'Anggota' },
        { value: 'Team', text: 'Team' },
      ],
    }
  },
  computed: {
    data() {
      return this.$store.state.member.detail
    },
  },
  created() {
    this.checkAuth()
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    getDetailData() {
      const { id } = this.$route.params
      this.$store.dispatch('member/loadDetailMember', id)
    },
    editRoleUser() {
      const { id } = this.$route.params
      const cid = localStorage.getItem('client_id')

      const formData = {
        role: this.data.role,
      }

      this.$axios.put(`${cid}/noauth/member/role/${id}`, formData)
        .then(() => {
          // Success code
          this.createToast('success', 'Edit role member', 'Edit role member success')
        })
        .catch(error => {
          console.log(error)
        })
    },

    gotoLink(link) {
      window.open(link, '_blank')
    },
    gotoEdit(id) {
      this.$router.push({ path: `/user/member/edit/${id}` })
    },
  },
}
</script>
